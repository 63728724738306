import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Headroom from "headroom.js";
import Menu from "./js/Menu";
import GMap from "./js/GMap";
import Form from "./js/Form";
import Animations from "./js/Animations";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import Slider from "./js/Slider";
import "./app.scss";

let actions = {};

function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

// barba.hooks.beforeEnter((data) => {});
// barba.hooks.after((data) => {
//   runActions();
// });

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);

runActions();

function runActions() {
  console.log("runActions");
  action("Slider", Slider);
  action("GMap", GMap);
  action("Form", Form);

  Menu(document.querySelectorAll(".menu-btn"));

  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 200,
  });
  headroom.init();

  const imgs = [...document.querySelectorAll(".img-reveal")];
  const triggers = [...document.querySelectorAll(".img-reveal-hover")];

  triggers.forEach((trig) =>
    trig.addEventListener("mouseenter", (e) => {
      console.log("enter");
      const activeIndex = triggers.indexOf(e.target);
      console.log(activeIndex);

      imgs.forEach((img) => {
        gsap.to(img, 0.3, {
          opacity: 0,
          onComplete() {
            imgs.forEach((img) => img.classList.add("hidden"));
            imgs[activeIndex].classList.remove("hidden");

            gsap.to(imgs[activeIndex], 0.3, {
              opacity: 1,
            });
          },
        });
      });
    })
  );
}
