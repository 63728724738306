import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";
gsap.registerPlugin(ScrollTrigger);

export default function Animations(el) {
  eventBus.on("scrolltrigger:refresh", () => ScrollTrigger.refresh());
}

//nav fade-ins
gsap.from(".nav-fade", { duration: 1, opacity: 0, delay: 1.8 });

//header fade-ins
gsap.from(".header-fade", { duration: 1, opacity: 0, delay: 1.8 });
gsap.from(".head-logo-fade", { duration: 1, opacity: 0, delay: 1.8 });
gsap.from(".link-fade1", { duration: 0.5, opacity: 0, delay: 1.4 });
gsap.from(".link-fade2", { duration: 0.5, opacity: 0, delay: 1.4 });

//text-fade
gsap.from(".text-fade-1", { duration: 0.5, opacity: 0, delay: 0.5 });
gsap.from(".text-fade-2", { duration: 0.5, opacity: 0, delay: 0.55 });
gsap.from(".text-fade-3", { duration: 0.5, opacity: 0, delay: 0.6 });
gsap.from(".text-fade-4", { duration: 0.5, opacity: 0, delay: 0.65 });
gsap.from(".text-fade-5", { duration: 0.5, opacity: 0, delay: 0.7 });
gsap.from(".text-fade-6", { duration: 0.5, opacity: 0, delay: 0.75 });
gsap.from(".text-fade-7", { duration: 0.5, opacity: 0, delay: 0.8 });
gsap.from(".text-fade-8", { duration: 0.5, opacity: 0, delay: 0.85 });

//text-fade secondary
gsap.from(".text-fade-9", { duration: 0.5, opacity: 0, delay: 0.9 });
gsap.from(".text-fade-10", { duration: 0.5, opacity: 0, delay: 0.95 });
gsap.from(".text-fade-11", { duration: 0.5, opacity: 0, delay: 1 });
gsap.from(".text-fade-12", { duration: 0.5, opacity: 0, delay: 1.05 });
gsap.from(".text-fade-13", { duration: 0.5, opacity: 0, delay: 1.1 });
gsap.from(".text-fade-14", { duration: 0.5, opacity: 0, delay: 1.15 });
gsap.from(".text-fade-15", { duration: 0.5, opacity: 0, delay: 1.2 });
gsap.from(".text-fade-16", { duration: 0.5, opacity: 0, delay: 1.25 });
gsap.from(".text-fade-17", { duration: 0.5, opacity: 0, delay: 1.25 });
gsap.from(".text-fade-18", { duration: 0.5, opacity: 0, delay: 1.25 });
gsap.from(".text-fade-19", { duration: 0.5, opacity: 0, delay: 1.25 });

//text-fade third

gsap.from(".text-fade-20", { duration: 0.5, opacity: 0, delay: 1.3 });
gsap.from(".text-fade-21", { duration: 0.5, opacity: 0, delay: 1.35 });
gsap.from(".text-fade-22", { duration: 0.5, opacity: 0, delay: 1.4 });
gsap.from(".text-fade-23", { duration: 0.5, opacity: 0, delay: 1.45 });

// Scalable logo

ScrollTrigger.create({
  trigger: ".holdMe",
  delay: 1,
  animation: gsap.fromTo(
    ".resizeMe",
    { scale: 0.5 },
    { scale: 90, y: "-1600px" }
  ),

  start: "top 60%",
  end: "top -70%",
  scrub: 1,
  // markers: true,
});

gsap.from(".wordAnimation", {
  duration: 3,
  opacity: 0,
  delay: 2,
  pin: true,
  stagger: 7,
  scrollTrigger: {
    trigger: ".wordAnimation",
    start: "top 70%",
    end: "top -60%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".text-fade-description", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-fade-description",
    start: "top 70%",
    end: "top 50%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".line-up", {
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".line-up",
    start: "top 100%",
    end: "top 60%",
    scrub: 2,
    // markers: true,
  },
});

// fullimage text block

gsap.from(".fade-up4", {
  duration: 1,
  y: "30%",
  scrollTrigger: {
    trigger: ".fade-up4",
    start: "top 150%",
    end: "top 0",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".fade-up7", {
  duration: 1,
  y: "30%",
  scrollTrigger: {
    trigger: ".fade-up7",
    start: "top 150%",
    end: "top 0",
    scrub: 2,
    // markers: true,
  },
});

//text-image block

gsap.from(".text-head-fade5", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-head-fade5",
    start: "top 95%",
    end: "top 70%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".text-head-fade6", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-head-fade6",
    start: "top 95%",
    end: "top 70%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".text-des-fade5", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-des-fade5",
    start: "top 95%",
    end: "top 70%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".text-des-fade6", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-des-fade6",
    start: "top 95%",
    end: "top 70%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".text-logo-fade5", {
  y: "30%",
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-logo-fade5",
    start: "top 95%",
    end: "top 70%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".text-logo-fade6", {
  y: "30%",
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-logo-fade6",
    start: "top 95%",
    end: "top 70%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".text-buttonL5", {
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-buttonL5",
    start: "top 95%",
    end: "top 75%",
    scrub: 2,
    // markers: true,
  },
});
gsap.from(".text-buttonL6", {
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-buttonL6",
    start: "top 95%",
    end: "top 75%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".text-buttonR5", {
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-buttonR5",
    start: "top 90%",
    end: "top 70%",
    scrub: 2,
    // markers: true,
  },
});
gsap.from(".text-buttonR6", {
  opacity: 0,
  scrollTrigger: {
    trigger: ".text-buttonR6",
    start: "top 90%",
    end: "top 70%",
    scrub: 2,
    // markers: true,
  },
});

//two-column block

gsap.from(".col-entry", {
  duration: 2,
  opacity: 0,
  pin: true,
  stagger: 1,
  scrollTrigger: {
    trigger: ".col-entry",
    start: "top 100%",
    end: "top 10%",
    scrub: 2,
    // markers: true,
  },
});

// accordion block

gsap.from(".accord-header", {
  duration: 2,
  opacity: 0,
  y: "100%",
  scrollTrigger: {
    trigger: ".accord-header",
    start: "top 90%",
    end: "top 60%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".accord-entry", {
  duration: 1,
  opacity: 0,
  pin: true,
  stagger: 1,
  scrollTrigger: {
    trigger: ".accord-entry",
    start: "top 100%",
    end: "top 40%",
    scrub: 2,
    // markers: true,
  },
});

// Contact block

gsap.from(".form-header", {
  duration: 2,
  opacity: 0,
  y: "100%",
  scrollTrigger: {
    trigger: ".form-header",
    start: "top 90%",
    end: "top 60%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".border-fade-1", {
  duration: 2,
  opacity: 0,
  x: "-100%",
  scrollTrigger: {
    trigger: ".border-fade-1",
    start: "top 90%",
    end: "top 80%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".border-fade-2", {
  duration: 2,
  opacity: 0,
  x: "-100%",
  scrollTrigger: {
    trigger: ".border-fade-2",
    start: "top 85%",
    end: "top 75%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".border-fade-3", {
  duration: 2,
  opacity: 0,
  x: "-100%",
  scrollTrigger: {
    trigger: ".border-fade-3",
    start: "top 80%",
    end: "top 70%",
    scrub: 2,
    // markers: true,
  },
});

gsap.from(".border-fade-4", {
  duration: 2,
  opacity: 0,
  x: "-100%",
  scrollTrigger: {
    trigger: ".border-fade-4",
    start: "top 90%",
    end: "top 60%",
    scrub: 2,
    // markers: true,
  },
});

// Text Image block

gsap.from(".textimage-entry", {
  duration: 1,
  opacity: 0,
  pin: true,
  stagger: 1,
  scrollTrigger: {
    trigger: ".textimage-entry",
    start: "top 100%",
    end: "top 40%",
    scrub: 2,
    // markers: true,
  },
});
